import React, {useEffect, useState} from 'react';

import {
  Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink,
} from 'reactstrap';
import {animateScroll} from 'react-scroll';


function clickScroll(e: Event) {
  const el = e.target as HTMLElement;
  const anchor = el.getAttribute('href');
  if (!anchor) {
    return;
  }
  const splitHref = anchor.split('#');
  const elementHash = splitHref[splitHref.length - 1];
  const element = document.getElementById(elementHash);
  if (!element) {
    return;
  }
  animateScroll.scrollTo(element.offsetTop);
};


function NavigationBar() {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const close = () => setIsOpen(false);

  useEffect(() => {
    const anchors = document.querySelectorAll('a[href*="#"]:not([href="#"])');
    for (let i = 0; i < anchors.length; i++) {
      anchors[i].addEventListener('click', clickScroll);
    }

    return () => {
      const anchors = document.querySelectorAll('a[href*="#"]:not([href="#"])');
      for (let i = 0; i < anchors.length; i++) {
        anchors[i].removeEventListener('click', clickScroll);
      }
    };
  });

  return (
    <Navbar fixed="top" light color="light" expand="md">
      <div className="container">
        <NavbarBrand href="#top">
          meta<span className="navbar-brand-second">grupp</span>
        </NavbarBrand>
        <NavbarToggler onClick={toggle}/>
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <NavLink href="#ettevottest" onClick={close}>Ettevõttest</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#kontakt" onClick={close}>Kontakt</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#kaart" onClick={close}>Kaart</NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </div>
    </Navbar>
  );
}

export default NavigationBar;
