import React from 'react';

import NavigationBar from './NavigationBar';


function App() {
  return (
    <>
      <NavigationBar />

      <div id="top" className="full-height-block container-wrapper-top text-center">
        <div className="full-height-block--cell container-overlay">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-10">
                <h1 className="mb-4">Metagrupp OÜ<br />(likvideerimisel)</h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="ettevottest" className="full-height-block">
        <div className="full-height-block--cell container-wrapper-highlight text-center">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-sm-10">
                <p>
                  OÜ METAGRUPP on 1998 aastal asutatud eraettevõte, mille tegevusalaks oli alumiiniumist ja teistest
                  kergmetallidest keevistoodete valmistamine.<br /><br />
                  Ligi 25 aasta jooksul oli põhitegevuseks Kirami OY-le 70000 kümblustünniahju ja aluminiumist
                  kümblustünni välja töötamine, juurutamine ning valmistamine.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="kontakt" className="full-height-block">
        <div className="full-height-block--cell container-wrapper-secondary container-wrapper-contact text-center">
          <div className="container">
            <div className="row">
              <div className="col-sm-4">
                <h4 className="my-4">Metagrupp OÜ</h4>
                <p className="contact-split">
                  Reg. nr: 10440094<br />
                  KMKR: EE100045619
                </p>
              </div>
              <div className="col-sm-4">
                <h4 className="my-4">Üldkontakt</h4>
                <p className="contact-split">
                  Tel: +372 530 41 675<br />
                  <a href="mailto:info@metagrupp.ee">info@metagrupp.ee</a>
                </p>
              </div>
              <div className="col-sm-4">
                <h4 className="my-4">Postiaadress</h4>
                <p>
                  Kauri tee 12a-4<br />
                  Alliku küla, 76403<br />
                  Saue Vald, Harju maakond
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="kaart" className="full-height-block">
        <div className="full-height-block--cell">
          <div id="mapLoo" className="map" />

          <div className="container-wrapper-contact text-center">
            <div className="container">
              <div className="row">
                <div className="col-sm-6">
                  <h4 className="my-4">Asukoht Lool</h4>
                  <p>
                    Saha tee 18<br />
                    Loo alevik, 74201<br />
                    Jõelähtme vald, Harju maakond
                  </p>
                </div>
                <div className="col-sm-6">
                  <h4 className="my-4">Kontakt</h4>
                  <p>
                    +372 530 41 675<br />
                    <a href="mailto:info@metagrupp.ee">info@metagrupp.ee</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
