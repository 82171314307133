// Polyfilling based on https://github.com/facebook/create-react-app/blob/master/packages/react-app-polyfill/README.md
import 'react-app-polyfill/ie11';

import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import './index.scss';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // TODO: Add strict mode back once the issue with reactstrap Collapse (Transition) gets fixed or element replaced
  // https://github.com/reactstrap/reactstrap/issues/1962
  // <React.StrictMode>
  //   <App/>
  // </React.StrictMode>,
  <App/>,
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// import reportWebVitals from './reportWebVitals';
// reportWebVitals();
